<template>
    <v-container fluid class="fill-height">
        <div id="vtrack-alert"></div>
        <v-row align="center" justify="center">
            <v-col cols="12" xs="12" sm="8" md="5" class="">
                <ValidationObserver v-slot="{ invalid }">
                    <v-form>
                        <v-card class="elevation-1 auth-v-card" style="padding-right: 30px; padding-left: 30px;">
                            <div class="logo-block">
                                <img class="justify-center" src="@/assets/logo.png" width="160" style="margin-top: 40px; margin-bottom: 20px;"/>
                            </div>
                            <div v-if="showMessage" class="login-alert text-center">
                                <b-alert :variant="messageType" show>{{ message }}</b-alert>
                            </div>
                            <div class="form-name">Welcome back!</div>
                            <div class="form-description">Sign in to continue</div>
                            <v-card-text style="margin-top: 20px;">
                                <ValidationProvider rules="required" name="Username" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="user.email"
                                        label="Email"
                                        :error-messages="errors[0]"
                                        type="text"
                                        prepend-inner-icon="mdi-email"
                                        outlined/>
                                </ValidationProvider>
                                <ValidationProvider rules="required" name="Username" v-slot="{ errors }"
                                                    style="height: 30px;">
                                    <v-text-field
                                        class="test"
                                        v-model="user.password"
                                        label="Password"
                                        :error-messages="errors[0]"
                                        type="password"
                                        outlined
                                        prepend-inner-icon="mdi-lock"/>
                                </ValidationProvider>
                            </v-card-text>
                            <div class="auth-button-block">
                                <v-btn class="auth-button" color="primary" :disabled="invalid" :loading="buttonLoading"
                                       @click="login">Login
                                </v-btn>
                            </div>
                            <div class="horizontal-line justify-center"><span>OR</span></div>
                            <div class="login-link">
                                <router-link to="/forgotPassword"><b>Forgot password?</b></router-link>
                            </div>
                        </v-card>
                    </v-form>
                </ValidationObserver>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "Login",
    data: () => ({
        user: {
            email: '',
            password: ''
        },
        loginerror: false,
        errormsg: '',
        showMessage: false,
        messageType: 'danger',
        message: '',
        buttonLoading: false,
        messageText: ''
    }),
    computed: {
        APP_TITLE() {
            return this.$store.getters.APP_TITLE
        }
    },
    methods: {
        login() {
            this.showMessage = false;
            this.$store.dispatch('login', this.user)
                .then(() => this.$router.push({name: 'OrderList'}))
                .catch(error => {
                    this.messageType = 'danger';
                    let errorMessage = '';
                    Object.keys(error.response.data.errors).forEach(function (key) {
                        error.response.data.errors[key].forEach(function (msg) {
                            errorMessage += '';
                            errorMessage += msg;
                            errorMessage += '';
                        });
                    });
                    this.message = errorMessage;
                    this.showMessage = true;
                })
        },
        errorName(errName) {
            return this.$store.getters.authStatus == errName
        }
    },
    mounted: function () {
        if (this.$route.params.message) {
            let message = this.$route.params.message;
            this.messageType = message.type;
            this.message = message.message;
            this.showMessage = true;
        }
    },
    created: function () {
        if (this.$store.getters.isLoggedIn) {
            this.$router.push({name: 'OrderList'})
        }
    }
}
</script>

<style scoped lang="scss">
.rotated {
    transform: scaleX(-1);
}

.login-alert {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.error-msg {
    font-size: 14px;
}

.error-msg {
    font-size: 14px;
}

.form-name {
    margin-top: 25px;
    margin-bottom: 0px;
    font-size: 24px;
    text-align: center;
}

.form-description {
    font-size: 14px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.logo-block {
    text-align: center;
}

.auth-v-card {
    box-shadow: none !important;
    border: 1px solid #DADCE0;
}

.auth-button-block {
    margin-top: -20px;
    margin-bottom: 30px;
    width: 100%;
    padding-left: 17px;
    padding-right: 17px;
}

.auth-button {
    text-transform: capitalize;
    width: 100%;
}

.horizontal-line {
    width: 80%;
    text-align: center;
    border-bottom: 1px solid #DADCE0;
    line-height: 0.1em;
    margin: 10px 0 20px;
    font-size: 13px;
    margin: 0 auto;
}

.horizontal-line span {
    background: #fff;
    color: #9E9E9E;
    padding: 0 10px;
}

.login-link {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.login-alert {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
}
</style>
<style lang="scss">
.v-input__prepend-inner {
    margin-top: 13px !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 50px !important;
}

.v-input .v-label {
    line-height: 15px !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    color: rgba(0, 0, 0, 0.20);
}

.theme--light.v-icon {
    color: rgba(0, 0, 0, 0.35);
}
</style>